<template>
  <div style="margin: 0px 20px">
    <div v-for="(value, index) in data" :key="index">
      <i :class="value.icon"></i>
      <span @click="goPush(value)" class="btn1">
        {{ value.name }}
      </span>
      <div class="body" v-if="value.children && value.children.length > 0">
        <span @click="goPush(item)" class="btn2" v-for="(item, i) in value.children" :key="i">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
// import MenuTree from "@/components/MenuTree";
export default {
  name: 'MenuTree',
  props: ['data'],
  // components: {
  // MenuTree: MenuTree
  // },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 跳转路由
    goPush(e) {
      if (this.$route.path != e.path) {
        this.$router.push(e.path);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn1 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.body {
  display: flex;
  flex-wrap: wrap;
  // margin-bottom: 15px;

  .btn2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    color: #b5b5b5;
    display: block;
    margin-right: 35px;
    cursor: pointer;
  }
}
</style>
