import $http from '@/utils/http';

export function getRbacMenus(param) {
  return $http.get('/v2/rbac/menus', param);
}

export function getRbacRole(param) {
  return $http.post('/v2/rbac/roles', param);
}

export function saveRbacRoles(param) {
  return $http.post('/v2/rbac/roles/save', param);
}

export function getSysMenus(param) {
  return $http.get('/v2/rbac/system_menus', param);
}

export function saveSysMenu(param) {
  return $http.post('/v2/rbac/system_menu/save', param);
}

export function deleteSysMenu(param) {
  return $http.post('/v2/rbac/system_menu/delete', param);
}

export function getSysRole() {
  return $http.get('/v2/rbac/system_roles');
}

export function saveSysRole(param) {
  return $http.post('/v2/rbac/system_role/save', param);
}

export function deleteSysRole(param) {
  return $http.post('/v2/rbac/system_role/delete', param);
}
