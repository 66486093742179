<template>
  <div class="app" style="width: 100%; height: 100%">
    <el-container>
      <el-header style="border: 0; background-color: #29303e">
        <el-row>
          <el-col :span="10">
            <div
              class="app-side-logo"
              style="text-align: center; display: flex; align-items: center"
            >
              <img
                style="width: 120px; margin: 0 10px; object-fit: cover"
                src="@/assets/logo_white.png"
              />
              <div
                style="color: #bbb; height: 25px; border-left: 1px solid #ffffff; margin-right: 5px"
              ></div>
              <span
                style="
                  font-size: 22px;
                  font-weight: 500;
                  margin: 0 10px;
                  color: #ffffff;
                  font-family: SmileySans;
                "
              >
                智装荟saas后台管理系统
              </span>
            </div>
          </el-col>
          <el-col :span="14" style="text-align: right; color: #ffffff; font-size: 14px">
            <span @click="logout" style="display: inline-block; padding: 0 15px; cursor: pointer">
              退出
            </span>
          </el-col>
        </el-row>
      </el-header>
      <el-container style="height: calc(100vh - 60px)">
        <el-aside style="background-color: #29303e" v-loading.lock="fullscreenLoading">
          <el-menu class="el-menu-vertical-demo hhhh" background-color="#29303E">
            <!-- 侧边导航组件 -->
            <MenuTree :data="routes" :activeName="activeName"></MenuTree>
          </el-menu>
        </el-aside>
        <el-main>
          <div v-if="isInit" class="app-wrap loadingDiv">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MenuTree from '@/components/MenuTree';
// import { loginOut } from '@/common/js/login.js';
import { getRbacMenus } from '@/common/rbac.js';
// import { Loading } from 'element-ui';
export default {
  name: 'Container',
  components: {
    MenuTree: MenuTree,
  },
  data() {
    return {
      openTab: [], //所有打开的路由
      activeIndex: '', //激活状态
      MainColor: '#FFFFFF',
      username: 'Admin',
      isCollapse: false,
      keepAlive: false,
      headImg: '',
      nickname: '',
      mobile: '',
      routes: [],
      routesList: [],
      fullscreenLoading: false,
      isInit: false,
      activeName: '',
    };
  },
  async created() {
    // this.getMenu();
    this.fullscreenLoading = true;
    let resp = await getRbacMenus();
    // console.log(resp);
    this.routes = resp.result.menus;

    await this.$store.dispatch('SET_RBAC', resp.result);
    this.isInit = true;
    this.fullscreenLoading = false;
  },
  watch: {
    $route(val) {
      this.activeName = val.name;
    },
  },
  methods: {
    logout() {
      this.$confirm('确认退出登录?', '提示', {})
        .then(() => {
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('token');
          this.$router.push('/login');
        })
        .catch(() => {});
    },
    // getMenu() {
    //     this.openFullScreen1();
    // },
    // openFullScreen1() {
    //     this.fullscreenLoading = true;
    //     setTimeout(() => {
    //         this.routes = this.routesList;
    //         this.fullscreenLoading = false;
    //     }, 500);
    // },
  },
};
</script>

<style>
.el-main {
  padding: 0;
}

.el-header {
  padding: 0 5px;
  line-height: 60px;
}

.el-aside {
  width: var(--el-menu-background-color) !important;
  line-height: 55px;
  color: #ffffff;
}

.el-aside .el-menu {
  border-right: 0px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  min-height: 400px;
}

.hhh {
  overflow-x: hidden;
  overflow-y: auto;
}

.app > ::-webkit-scrollbar {
  display: none;
}
</style>
